.input-block {
  cursor: not-allowed;

  background-color: #eeeeee;
}

/* Disposition css */

.yondro {
  display: grid;
  width: auto;
  height: auto;
  grid-template-rows: 16% 40% 40%;
  grid-gap: 4px;
}
.part1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50% 50%;
  height: 45px;
  grid-gap: 4px;
  row-gap: 15px;
}
.item1 {
  width: 80px;
  height: 30px;
  grid-column-start: 1;
  border: 2px solid;
  border-color: green;
  color: green;
  text-align: center;
  border-radius: 25px;
}
.item2 {
  grid-row-start: 2;
  grid-column: 1/3;

  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.part2 {
  display: grid;
  width: auto;
  height: 145px;
  grid-template-columns: 20% 80%;
  grid-template-rows: 30% 70%;
  grid-gap: 4px;
  margin-top: 25px;
}
.items1 {
  grid-column-start: 1;
  display: grid;
  height: 120px;
}
.items2 {
  grid-column: 2/3;
}
.items3 {
  grid-row-start: 2;
  height: 25px;
}
.items4 {
  grid-column: 2/3;
}

.items4 {
  height: 80px;
}

span {
  margin-right: 5px;
}

/* .table-responsive {
  max-height: 160px;
} */
