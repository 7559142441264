.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tbody {
  display: flex;
  flex-direction: column-reverse;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.paginationBttns {
  width: 50%;
  height: 40px;
  list-style: none;
  display: inline-flex;
  justify-content: flex-start;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #0d6efd;
  color: #0d6efd;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #0d6efd;
}

.paginationActive a {
  color: rgb(255, 255, 255);
  background-color: #0d6efd;
}

.paginationDisabled a {
  color: white;
  background-color: grey;
}

.block {
  display: inline-block;
}

.row {
  background-color: #ffffff;
}

nav.navbar {
  background: #e9e9e9;
}

.sidebaricon {
  background-color: #c0c0c0;
  margin-left: -10px;
}

.comment {
  width: 259px;
  padding: 5px 0 0 10px;
  height: 80px;
  resize: none;
}

.signinbox {
  width: 300px;
}

.report_total {
  border-top: 2px solid !important;
  /* border-bottom: 10px solid !important; */
  border-color: #746d6d !important;
}

.row {
  --bs-gutter-x: -35.5rem !important;
}

.freshleads {
  color: #17a2b8;
}

#OvalSpinner {
  justify-content: center !important;
}
